<template>
    <div class="flex flex-row w-full bg-[#F7F7F7]" v-if="!this.assumeIdentity">
        <div class="w-4/12 h-screen py-16 px-10">
            <div class="flex flex-col bg-[#fff] w-full h-full min-h-[625px] justify-center rounded-2xl px-[70px] py-16">
                <h2 class="text-4xl font-medium mb-5">Log in bij ParisXP</h2>
                <p class="text-base text-greyghost mb-10">Voer je gegevens in om in te loggen bij ParisXP</p>
                <!-- <span class="inline-flex justify-center w-full border-b-[1px] border-greyghost my-6">
                    <span class="px-3 -mb-[7px] bg-[#fff] text-xs text-greyghost">Of met logingegevens</span>
                </span> -->
                <div class="flex flex-col">
                    <Transition name="fade">
                        <Toast :message="errorMessage" :relative="true" v-if="error"></Toast>
                    </Transition>
                    <label class="mb-2 text-sm">Gebruikersnaam <b class="mx-2">of</b> Emailadres</label>
                    <input type="text" class="usergroup-input w-full form-input px-4 py-3 rounded-xl border-greypunch placeholder-greyghost focus:pl-8 transition-all" :class="v$.email.$error ? 'border-[red]' : ''" placeholder="Voer je email adres in" v-model="email" @blur="v$.email.$touch" />
                    <div v-for="error of v$.email.$errors" :key="error.$uid" class="text-[red] text-sm">Gebruikersnaam of Emailadres is verplicht</div>
                    
                    <label class="mt-6 mb-2 text-sm">Wachtwoord</label>
                    <div class="relative mb-14">
                        <input v-if="showPassword == false" type="password" class="usergroup-input w-full form-input px-4 py-3 rounded-xl border-greypunch placeholder-greyghost text-base focus:pl-8 transition-all" :class="v$.password.$error ? 'border-[red]' : ''" placeholder="Voer je wachtwoord in" v-model="password" @blur="v$.password.$touch" />
                        <input v-else type="text" class="usergroup-input w-full form-input px-4 py-3 rounded-xl border-greypunch placeholder-greyghost text-base focus:pl-8 transition-all" :class="v$.password.$error ? 'border-[red]' : ''" placeholder="Voer je wachtwoord in" v-model="password">
                        <div v-for="error of v$.password.$errors" :key="error.$uid" class="text-[red] text-sm">Wachtwoord is verplicht</div>
                        <div class="flex absolute inset-y-0 right-4 items-center text-xs text-dstnygreen hover:cursor-pointer" @click="togglePassword()">
                            <span v-if="showPassword == false">Show</span>
                            <span v-if="showPassword == true">Hide</span>
                        </div>
                    </div>
                    <button class="login" :class="((email != null && password != null) && (email != '' && password != '')) ? '' : 'button-disabled'" @click="login()" :disabled="((email != null && password != null) && (email != '' && password != '')) == ''">Log in</button>
                </div>
                <span class="text-dstnygreen place-self-center text-xs mt-16 hover:cursor-pointer font-bold" @click="sendToPwReset()">Wachtwoord vergeten?</span>
            </div>
        </div>
        <div class="flex flex-col w-8/12 py-16 px-16">
            <div class="flex flex-row items-center">
                <img src="@/assets/img/logo_paris.png" class="mr-8" />
                <h1 class="text-4xl text-greyghost">Kennis & Support op één plek</h1>
            </div>
            <img src="@/assets/img/login_img.svg" class="my-auto place-self-center" />
            <!-- <div class="flex flex-row w-2/4 justify-between place-self-center text-dstnygreen">
                <a href="">Over Paris</a>
                <a href="">Over T-Mobile</a>
                <a href="">Cookies</a>
                <a href="">Privacy statement</a>
            </div> -->
        </div>
    </div>
    <div class="flex flex-row w-full bg-[#F7F7F7]" v-else>
        <div class="w-screen h-screen">
            
        </div>
    </div>
</template>
<script>
import Toast from "@/components/Toast.vue";
import { useVuelidate } from '@vuelidate/core'
import { required, email, alphaNum, or } from '@vuelidate/validators'
    
export default {
    name: 'LoginView',
    components: { Toast },
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            showPassword: false,
            email: null,
            password: null,
            error: false,
            errorMessage: ""
        }
    },
    validations () {
        return {
            email: { 
                required,
                mycustomval: or(email, alphaNum)
            },
            password: { required },
        }
    },
    created() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        this.assumeEmail = urlParams.get('assumeEmail');
        this.assumePassword = urlParams.get('assumePassword');

        if(this.assumeEmail !== null && this.assumePassword !== null) {
            this.doAssumeIdentity(this.assumeEmail, this.assumePassword);
        }
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
        },
        getAccountId(emailadres) {
            this.$axios.get(this.apiUrl+'/wp-json/paris/v1/user/search', {
                params: {
                    query: emailadres,
                }
            }).then(response => {

                if(response.data.length > 0) {
                    this.$store.commit('setAccountId', response.data[0].accountId);
                }

                this.$router.push('/dashboard');

            })
        },
        login() {
            
            this.v$.$touch();
            this.error = false;

            var data = new FormData();
            data.append('email', this.email);
            data.append('password', this.password);
            // data.append('staginglogin', true);

            const cacheBuster = (url) => `${url}?cb=${Date.now()}`;
            var app = this;

            this.$axios.post(cacheBuster(this.apiUrl+'/wp-json/paris/v1/users/assumeId'), data).then(response => {
            //    localStorage.setItem('user', JSON.stringify(response.data));
                this.$store.commit('setUser', JSON.stringify(response.data));
                var emailfromApi = response.data.user_email;
               
                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+response.data.user_group).then(response => {
                    this.$store.commit('setUsergroup', JSON.stringify(response.data));
                    this.$store.commit('setCurrentUg', JSON.stringify(response.data));
                    this.$store.commit('setLoggedin', true);
                    this.$store.commit('setUserEmail', emailfromApi);

                    var usergroupdata = response.data;

                    this.$axios.get(this.apiUrl+'/wp-json/wp/v2/media/'+usergroupdata.acf.klant_logo).then(response => {

                        this.$store.commit('setUsergroupImg', response.data.source_url);

                    });

                    this.getAccountId(emailfromApi);

                })
            }).catch(function (error) {
                if((app.email == '' && app.password == '') || (app.email == null && app.password == null)) {
                    app.error = false;
                } else {
                    if (error.response.data.message == "No user found") {
                        app.error = true;
                        app.errorMessage = "Oeps... Er kan geen gebruiker gevonden worden met de ingevoerde Gebruikersnaam of E-mailadres";
                    } else if (error.response.data.message == "User is not allowed to login due to invalid login credentials") {
                        app.error = true;
                        app.errorMessage = "Oeps... Er is een onjuist wachtwoord ingevoerd<br/>Gebruik <a href='/wachtwoord-vergeten' class='underline'>wachtwoord vergeten</a> om een nieuw wachtwoord aan te maken.";
                    }
                }
            });
     
        },
        doAssumeIdentity(email,password) {
            this.assumeIdentity = true;
            this.error = false;

            var data = new FormData();
            data.append('email', email);
            data.append('password', password);

            const cacheBuster = (url) => `${url}?cb=${Date.now()}`;

            this.$axios.post(cacheBuster(this.apiUrl+'/wp-json/paris/v1/users/login'), data).then(response => {
            //    localStorage.setItem('user', JSON.stringify(response.data));
                this.$store.commit('setUser', JSON.stringify(response.data));
                var emailfromApi = response.data.user_email;
               
                this.$axios.get(this.apiUrl+'/wp-json/wp/v2/gebruikersgroep/'+response.data.user_group).then(response => {
                    this.$store.commit('setUsergroup', JSON.stringify(response.data));
                    this.$store.commit('setCurrentUg', JSON.stringify(response.data));
                    this.$store.commit('setLoggedin', true);
                    this.$store.commit('setUserEmail', emailfromApi);

                    if(response.data.jira_partner == '' || response.data.jira_partner == null || response.data.jira_partner == undefined) {
                        this.$store.commit('sethasJiraPartner', false);
                    } else {
                        this.$store.commit('sethasJiraPartner', true);
                    }

                    var usergroupdata = response.data;

                    this.$axios.get(this.apiUrl+'/wp-json/wp/v2/media/'+usergroupdata.acf.klant_logo).then(response => {

                        this.$store.commit('setUsergroupImg', response.data.source_url);

                    });

                    this.getAccountId(emailfromApi);
                    this.$store.commit('setAssumeIdentity', true);

                })
            });

        },
        sendToPwReset() {
            this.$store.commit('setUserEmail', this.email);
            this.$router.push('/wachtwoord-vergeten');
        }
    }
}
</script>
