<template>
    <TransitionRoot :show="isOpen" as="template">
        <Dialog @close="setClosed" class="relative z-50">

            <TransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-[#000]/80" aria-hidden="true" />
                

                <div class="fixed w-screen inset-0 flex items-start justify-center p-4 pt-[12vh]">
                    <DialogPanel class="md:w-full max-w-3xl bg-[#fff] rounded-xl shadow-2xl">
                        <searchbar :supersearch="true" @updateCloseSuperSearch="isOpen = $event"></searchbar>
                    </DialogPanel> 
                </div>
            </TransitionChild>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { mapGetters } from "vuex";
import logo from '@/assets/img/Default-paris-icon.png';
import Searchbar from './Searchbar.vue';

export default {
    name: "SuperSearch",
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        Searchbar
    },
    data() {
        return {
            showSearch: false,
            isOpen: false,
            searchTerm: "",
            articleResults: [],
            nieuwsResults: [],
            ticketResults: [],
            hasSearched: false,
            oeps: false,
            timeout: null,
            loading: false,
            logo: logo
        }
    },
    computed: {
        ...mapGetters(["loggedin","disableSupersearch","usergroup"])
    },
    methods: {
        openSuperSearch() {

            document.addEventListener('keydown', (e) => {

                if(this.loggedin == true) {

                    if(this.disableSupersearch == false) {

                        if (e.key == 'k' && (e.ctrlKey || e.metaKey)) {

                            e.preventDefault();

                            if (!document.body.getAttribute('data-once')) {
                                document.body.setAttribute('data-once', 'true');
                                this.isOpen = !this.isOpen
                            } else {
                                if (document.body.getAttribute('data-once') == 'false') {
                                    document.body.setAttribute('data-once', 'true');
                                    this.isOpen = !this.isOpen;
                                    if(this.articleResults.length == 0) {
                                        this.hasSearched = false;
                                    }
                                }
                            } 
                            
                        }
                    }
                } else {

                        this.disableSupersearch = true;

                }

            });

        },
        setClosed() {
            this.isOpen = false;
            document.body.setAttribute('data-once', 'false');
            this.hasSearched = false;
        },
        setIsOpen() {
            this.isOpen = !this.isOpen
            document.body.setAttribute('data-once', 'true');
            // document.body.setAttribute('data-once', 'false');
        },
        resetInput() {
            this.$refs.searchTerm.value = '';
            this.articleResults = [];
            this.ticketResults = [];
            this.oeps = false;
            this.hasSearched = false;
        }
    },
    created: function() {

        this.openSuperSearch();
        
    },    
}
</script>
<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"/>
<style>
@keyframes fadeSearch {
  0% {
    opacity: 0;
    top: 100%;
  }
  10% {
    opacity: 0;
    top: 0%;
  }
  100% {
    opacity: 1;
    top: 0%;
  }
}
</style>